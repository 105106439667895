import _ from "lodash";
import Vue from "vue";

/**
 * !!!! IMPORTANT !!!!
 * If you change something on this constant object, you also need to change it on the corresponding
 * PHP version of the file located in:
 *
 * app/Figured/Contracts/Locale/LocaleInterface.php
 */

/** ISO 3166-1 alpha-2 */
export const COUNTRY_CODES = Object.freeze({
    AUSTRALIA:      "AU",
    NEW_ZEALAND:    "NZ",
    USA:            "US",
    UNITED_KINGDOM: "GB",
    IRELAND:        "IE",
    SOUTH_AFRICA:   "ZA",
    CANADA:         "CA",
});

/**
 * !!!! IMPORTANT !!!!
 * If you change something on this constant object, you also need to change it on the corresponding
 * PHP version of the file located in:
 *
 * helpers.php
 */
export const PRODUCT_VERSIONS = Object.freeze({
    PV_REST_OF_WORLD:  "REST_OF_WORLD",
    PV_NEW_ZEALAND:    "NEW_ZEALAND",
    PV_AUSTRALIA:      "AUSTRALIA",
    PV_UNITED_KINGDOM: "UNITED_KINGDOM",
    PV_UNITED_STATES:  "UNITED_STATES",
    PV_CANADA:         "CANADA",
    PV_IRELAND:        "IRELAND",
    PV_MAGNIFY:        "MAGNIFY",
    PV_PROMAR:         "PROMAR",
});

/**
 * !!!! IMPORTANT !!!!
 * If you change something on this constant object, you also need to change it on the corresponding
 * PHP version of the file located in:
 *
 * app/Models/Product.php
 */
export const PRODUCT_CODES = Object.freeze({
    STANDALONE:                     "sa",
    FINANCIAL_ADVISER:              "fa",
    FINANCIAL_FARMER:               "ff",
    FINANCIAL_FARMER_PRACTICE:      "ffp",
    STOCK_TRACKER:                  "st",
    EOYR:                           "ey",
    EOYR_AUS:                       "ey_au",
    FINANCIAL_MANAGER:              "fm",
    COMMERCIAL_MANAGER:             "cm",
    MULTI_FARM:                     "fmf",
    LITE:                           "lite",
    LITE_SCENARIOS:                 "ls",
    REPORTING_GROUP:                "rg",
    MULTI_ENTERPRISE:               "cme",
    COMPLIANCE_MANAGER:             "lc",
    FIELD_ADD_ONS_FARM_MANAGER:     "fao-ff",
    FIELD_ADD_ONS_FARM_REPORTER:    "fao-lite",
});

export const FIELD_ADD_ON_FREE_LIMIT = 25;
export const FIELD_ADD_ON_CAPACITY   = 50;

/**
 * !!!! IMPORTANT !!!!
 * If you change something on this constant object, you also need to change it on the corresponding
 * PHP version of the file located in:
 *
 * app/Models/Tracking.php
 */
export const TRACKINGS = Object.freeze({
    ALL:          "all",
    CONSOLIDATED: "consolidated",
    UNASSIGNED:   "unassigned",
});

/**
 * !!!! IMPORTANT !!!!
 * If you change something on this constant object, you also need to change it on the corresponding
 * PHP version of the file located in:
 *
 * src/Figured/Contracts/Core/Permissions/Services/BetaPermissionsInterface.php
 */
export const BETA_PERMISSIONS = Object.freeze({
    BETA_BCMS_INTEGRATION:           "bcms_integration",
    BETA_ALLOCATOR_ALL_ACCOUNTS: "allocator_all_accounts",
    BETA_MARKET_VALUE_BALANCE_SHEET: "market_value_balance_sheet",
    BETA_CROPS_V3: "crops3",
    BETA_GENERIC_TRACKER: "generic_tracker",
    BETA_JOHN_DEERE_INTEGRATION: "john_deere_integration",
    BETA_TRIAL_FINANCIAL_FARMER: "trial_financial_farmer",
    BETA_AGWORLD_INTEGRATION: "agworld_integration",
    BETA_VALUATIONS_OPEN_CLOSE_ACC: "valuations_opening_closing_accounts",
    BETA_INSIGHTS_REPORT: "insights_report",
    BETA_REPORT_CHARTS: "report_charts_rabobank_1",
    BETA_SIMPLE_REPORTS: "simple_reports",
});

/**
 * Returns the global value of a given property, or returns a given default if
 * the property does not exist as a global.
 *
 * It's possible that globalVars is not defined. We should prevent that from
 * crashing all other scripts.
 *
 * TODO: we should set whatever we set in globalVars in middleware maybe (on the Figured object via JavaScript facade).
 * TODO: we should refactor all direct usages of "globalVars" and "Figured".
 * TODO: we should ideally expose all globals as methods, and remove the "get" and "set" methods.
 *       this would be significantly safer than using string literals that fail quietly.
 */
let facade, master; // JavaScript facade

export function reset() {
    master = (typeof globalVars === "object" ? globalVars : {});
    facade = (typeof Figured    === "object" ? Figured    : {});

    master = Vue.observable(master);
    facade = Vue.observable(facade);
}
reset();

/**
 * @deprecated We should expose all globals through functions, not by string key.
 *             Page-specific values (not project globals like farm id) should be
 *             injected using the facade as something like "PageData" or  "included".
 *             The ideal is to not consider the facade here at all, only globalVars.
 *             As a result, this method should disappear.
 *
 * This function may exist but should not be exported.
 */
export function get(path, fallback = null) {
    return _.get(facade, path, _.get(master, path, fallback));
}

/**
 * @deprecated We should expose all globals through functions, not by string key.
 *             Page-specific values (not project globals like farm id) should be
 *             injected using the facade as something like "PageData" or  "included".
 *             The ideal is to not consider the facade here at all, only globalVars.
 *             As a result, this method should disappear.
 *
 * This function may exist but should not be exported.
 */
export function set(path, value) {
    return _.set(facade, path, value);
}

/**
 * @return {boolean}
 */
export function debug() {
    return get("debug", false) === true;
}

/**
 * @returns {String|null} the user id of the user that is currently logged in.
 */
export function getActiveUserId() {
    return get("activeUserId");
}

/**
 * @returns {String|null} the email of the user that is currently logged in.
 */
export function getActiveUserEmail() {
    return get("activeUserEmail");
}

/**
 * @returns {String} the timezone of the current farm (passed to moment).
 */
export function getActiveFarmTimezone() {
    return get("activeFarmTimezone");
}

/**
 * @returns {String} the country code of the current farm.
 */
export function getActiveFarmCountryCode() {
    return get("activeFarmCountryCode");
}

/**
 * @returns {String} the product version of the current farm.
 */
export function getActiveFarmProductVersion() {
    return get("activeFarmProductVersion");
}

/**
 * @returns {String} the currency of the current farm.
 */
export function getActiveFarmCurrency() {
    return get("activeFarmCurrency");
}

/**
 * @returns {String} the currency of the current farm.
 */
export function getActiveFarmCurrencySubUnit() {
    return get("activeFarmCurrencySubUnit");
}

/**
 * @returns {Boolean} if the user/org 'farm read and write' permission set
 */
export function hasFarmReadWritePerm() {
    return get("hasFarmReadWritePerm");
}

/**
 * @returns {Boolean} if the user/org has 'farm read' permission set
 */
export function hasFarmReadPerm() {
    return get("hasFarmReadPerm");
}

/**
 * @returns {Boolean} if the user/org has 'farm admin' permission set
 */
export function hasFarmAdminPerm() {
    return get("hasFarmAdminPerm");
}

/**
 * @returns {Boolean} if the user/org has 'farm adviser' permission set
 */
export function hasFarmAdviserPerm() {
    return get("hasFarmAdviserPerm");
}

export function hasOrgPortfoliosPerm() {
    return get("hasOrgPortfoliosPerm");
}

export function hasFailedHealthChecks() {
    return get("isActiveFarmHealthChecksFailed");
}

/**
 * @returns {Boolean} if the user is a staff member
 */
export function hasFiguredStaffPerm() {
    return get("hasFiguredStaffPerm");
}

/**
 * @returns {Boolean} if the user/org has 'data only' permission set
 */
export function hasFarmDataOnlyPerm() {
    return get("hasFarmDataOnlyPerm");
}

/**
 * @returns {Boolean} If the user and farm was CSR enabled (BNZ specific permission)
 */
export function hasCsrEnabledPerm() {
    return get("hasCsrEnabledPerm");
}

/**
 * @returns {Object} the available years of the current farm.
 */
export function getActiveFarmYears() {
    return get("activeFarmYears");
}

/**
 * @returns {boolean}
 */
export function isActiveFarmNz() {
    return getActiveFarmProductVersion() === PRODUCT_VERSIONS.PV_NEW_ZEALAND;
}

/**
 * @returns {boolean}
 */
export function isActiveFarmAu() {
    return getActiveFarmProductVersion() === PRODUCT_VERSIONS.PV_AUSTRALIA;
}

/**
 * @returns {boolean}
 */
export function isActiveFarmCa() {
    return getActiveFarmProductVersion() === PRODUCT_VERSIONS.PV_CANADA;
}

/**
 * @returns {boolean}
 */
export function isActiveFarmMagnify() {
    return getActiveFarmProductVersion() === PRODUCT_VERSIONS.PV_MAGNIFY;
}

/**
 * @returns {boolean}
 */
export function isActiveFarmUk() {
    return getActiveFarmProductVersion() === PRODUCT_VERSIONS.PV_UNITED_KINGDOM;
}

/**
 * @returns {boolean}
 */
export function isActiveFarmUs() {
    return getActiveFarmProductVersion() === PRODUCT_VERSIONS.PV_UNITED_STATES;
}

/**
 * @returns {boolean}
 */
export function isActiveFarmPromar() {
    return getActiveFarmProductVersion() === PRODUCT_VERSIONS.PV_PROMAR;
}

/**
 * @returns {boolean}
 */
export function isActiveFarmIe() {
    return getActiveFarmProductVersion() === PRODUCT_VERSIONS.PV_IRELAND;
}

export function getActiveFarmProduct() {
    return get("activeFarmProduct");
}

export function isActiveFarmProduct(product) {
    return getActiveFarmProduct() === product;
}

export function isLiteYearEndMode() {
    return get("isLiteYearEndMode")
}

export function isActiveFarmReportingGroup() {
    return get("activeFarmIsReportingGroup");
}

export function isActiveFarmMultiFarm() {
    return get("activeFarmIsMultiFarm");
}

export function isActiveFarmLite() {
    return get("activeFarmIsLite");
}

export function isMultiFarmOnlyUser() {
    return get("isMultiFarmOnlyUser");
}

export function isActiveFarmCommercialManager() {
    return getActiveFarmProduct() === PRODUCT_CODES.COMMERCIAL_MANAGER;
}

export function isActiveFarmStandalone() {
    return getActiveFarmProduct() === PRODUCT_CODES.STANDALONE;
}

export function isForecastAllocatorEnabled() {
    return get("isForecastAllocatorEnabled");
}

export function isActiveFarmIntuit() {
    return get("activeFarmIsIntuit");
}

export function isActiveFarmXero() {
    return get("activeFarmIsXero");
}

export function getActiveFarmCurrentTracking() {
    return get("activeFarmCurrentTracking");
}

/**
 * @returns {String|null} the code of the current farm.
 */
export function getActiveFarmCode() {
    return get("activeFarmCode");
}

/**
 * @return {String|null} the Xero short code of the current farm.
 */
export function getActiveFarmShortCode() {
    return get("activeFarmShortCode");
}

/**
 * @return {String|null} The current accounting system being used by the farm.
 */
export function getActiveAccountingSystem() {
    return get("activeAccountingSystem");
}

/**
 * @return {String} Label for current accounting system used by the farm (upper case) - fallback is 'your accounting system'.
 */
export function getActiveAccountingSystemLabel() {
    return get("activeAccountingSystemLabel");
}

/**
 * Get the label for a specific accounting system (NOT TIED TO THE ACTIVE FARM)
 */
export function getLabelByAccountingSystem(accountingSystem) {
    const accountingSystemTitles = get("accountingSystemTitles");

    if (accountingSystemTitles[accountingSystem] ?? false) {
        return accountingSystemTitles[accountingSystem]
    }

    return "";
}

/**
 * @returns {String|null} the id of the current farm.
 */
export function getActiveFarmId() {
    return get("activeFarmId");
}

/**
 * @returns {String|null} the id of the current org.
 */
export function getActiveOrgId() {
    return get("activeOrgId");
}

/**
 * @returns {String|null} the slug of the current org.
 */
export function getActiveOrgSlug() {
    return get("activeOrgSlug");
}

/**
 * @returns {String|null} the name of the current org.
 */
export function getActiveOrgName() {
    return get("activeOrgName");
}

export function getActiveFarmCurrentSeasonYear() {
    return get("activeFarmCurrentSeasonYear");
}

/**
 * @returns {String|null} the country code of the current org.
 */
export function getActiveOrgCountry() {
    return get("activeOrgCountry");
}

/**
 *
 */
export function getAppLocale() {
    return get("appLocale", "en");
}

/**
 * @returns {Array} the PlanningTypeCascader options
 */
export function getPlanningTypeOptions() {
    return get("planningTypeOptions");
}

/**
 * @returns {Object} available snapshots
 */
export function getSnapshots() {
    return get("snapshots") || {};
}

/**
 * @returns {String} The language locale that should be used by moment.js
 *
 * @todo We should only expose the app locale and move the lookup from LogicInterface to the dates module.
 */
export function getMomentJsLocale() {
    return get("momentJsLocale", "en");
}

/**
 * @returns {String|null} the key that should be used when connecting with Pusher.js
 */
export function getPusherKey() {
    return get("pusherKey");
}

/**
 * @returns {String} the cluster option that should be used when connecting with Pusher.js
 */
export function getPusherCluster() {
    return get("pusherCluster");
}

/**
 * Returns true if active farm is a demo farm, otherwise returns false
 * @returns {any}
 */
export function activeFarmIsDemo() {
    return get("activeFarmIsDemo");
}

/**
 * returns the multi type name for active farm (e.g. farm, entity)
 * @returns {any}
 */
export function getProductMultiTypeName() {
    return get("productMultiTypeName")
}

/**
 * @returns {String|null} The DSN that will be used for error reporting via Sentry.
 */
export function getSentryDsn()
{
    return get("sentryDsnPublic");
}

/**
 * Checks to see if farm is on rolling plan
 *
 * @returns {Boolean}
 */
export function onRollingPlan()
{
    return get("onRollingPlan", false);
}

export function getActiveFarmLockDate() {
    return get("activeFarmLockDate");
}

export function getIsActiveFarmLockDateEnabled() {
    return get("isActiveFarmLockDateEnabled");
}

/**
 * Checks to see if budgets are visible
 *
 * @returns {Boolean}
 */
export function budgetsNotVisible()
{
    return get("budgetsNotVisible", false);
}

/**
 * Returns the current CSRF token.
 *
 * @returns {String}
 */
export function getCSRF() {
    return _.get(document.querySelector("meta[name=\"csrf-token\"]"), "content");
}

/**
 * Replaces the current CSRF token.
 *
 * @returns {String}
 */
export function setCSRF(token) {
    _.set(document.querySelector("meta[name=\"csrf-token\"]"), "content", token);

    /* Set all existing form field CSRF as well. */
    $("input[name=\"_token\"]").each(function() {
        $(this).val(token);
    });
}

/**
 * Checks to see if product type is able to connect to an Accounting System packages
 *
 * @returns {Boolean}
 */
export function isAccountingSystemCapable(productCode) {
    const invalidProductCodes = [PRODUCT_CODES.STANDALONE, PRODUCT_CODES.REPORTING_GROUP];

    return invalidProductCodes.indexOf(productCode) === -1;
}

/**
 * Checks to see if product type is able to be used in benchmarking
 *
 * @returns {Boolean}
 */
export function isBenchmarkingCapable(productCode) {
    const invalidProductCodes = [PRODUCT_CODES.COMMERCIAL_MANAGER, PRODUCT_CODES.REPORTING_GROUP];

    return invalidProductCodes.indexOf(productCode) === -1;
}
